body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.site-layout-background {
  background: #fff;
}

.w-100 {
  width: 100%;
}

.ant-table-cell p, .ant-modal-confirm-content p, div.ant-typography, .braft-render p {
  margin-bottom: 4px;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fffae6;
}

.image-wrap, .image-wrap img {
  max-width: 100%;
}

.ant-table td {
  vertical-align: top;
}

div.ant-table-wrapper::-webkit-scrollbar {
  width: auto;
  height: auto;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  outline-offset: -2px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}